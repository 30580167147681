/** @jsxImportSource react */
import { getCookie, setCookie } from '@common/helpers/cookie';
import {
  addToLocalStorage,
  retrieveLocalStorage,
} from '@common/helpers/localStorage';
import EntityTile from '@react/card';
import { usePrefetch } from '@react/services/api';
import { eventNames, useEvents } from '@react/services/events';
import Carousel from '@react/shared/components/Carousel';
import Dialog from '@react/shared/components/Dialog';
import Spinner from '@react/shared/components/Spinner';
import TabbedContent from '@react/shared/components/TabbedContent';
import { Community } from '@sly/core/src/types';
import { Dropdown } from '@sly/icons/react';
import { useEffect, useState } from 'react';
import { isBrowser } from 'sly/config';

const LIKED_COMMUNITIES_COOKIE = 'sly_liked_communities';
const DISLIKED_COMMUNITIES_COOKIE = 'sly_disliked_communities';

export interface RecsProps {
  forceVisible?: boolean;
}

const Recs = ({ forceVisible = false }: RecsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(forceVisible);
  const [likedCommunities, setLikedCommunities] = useState(new Set());
  const [dislikedCommunities, setDislikedCommunities] = useState(new Set());

  const { events } = useEvents();

  useEffect(() => {
    const recsOpen = retrieveLocalStorage('recs_open');

    // If cookie is set or forceVisible is true, show the button
    setIsVisible(
      (recsOpen !== '' && recsOpen !== undefined && recsOpen != null) ||
        forceVisible
    );

    let likedCommunities =
      getCookie(LIKED_COMMUNITIES_COOKIE)?.split(',') || [];
    let dislikedCommunities =
      getCookie(DISLIKED_COMMUNITIES_COOKIE)?.split(',') || [];
    likedCommunities = likedCommunities?.filter((value: any) => value);
    dislikedCommunities = dislikedCommunities?.filter((value: any) => value);

    setLikedCommunities(new Set(likedCommunities));
    setDislikedCommunities(new Set(dislikedCommunities));
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
    addToLocalStorage('recs_open', !isOpen);

    events.track(eventNames.ButtonClicked, {
      location: 'recommendationModule',
      text: isOpen ? 'close' : 'open',
    });
  };

  const { requestInfo, fetch } = usePrefetch(
    'getRecommendations',
    {
      include: 'all',
    },
    {
      shouldBail:
        !isBrowser ||
        retrieveLocalStorage('recs_open') === '' ||
        retrieveLocalStorage('recs_open') === undefined ||
        retrieveLocalStorage('recs_open') === null,
    }
  );

  // Take the sly_liked_communities cookie and appent new slug to it
  const updateCookie = (
    slug: string,
    cookie: typeof LIKED_COMMUNITIES_COOKIE | typeof DISLIKED_COMMUNITIES_COOKIE
  ) => {
    // If the cookie already includes the slug, return
    if (
      (likedCommunities.has(slug) && cookie === LIKED_COMMUNITIES_COOKIE) ||
      (dislikedCommunities?.has(slug) && cookie === DISLIKED_COMMUNITIES_COOKIE)
    ) {
      return;
    }

    // If the cookie includes the slug and the opposite cookie, remove it
    if (likedCommunities?.has(slug) && cookie === DISLIKED_COMMUNITIES_COOKIE) {
      likedCommunities.delete(slug);
    } else if (
      dislikedCommunities?.has(slug) &&
      cookie === LIKED_COMMUNITIES_COOKIE
    ) {
      dislikedCommunities.delete(slug);
    }

    cookie === LIKED_COMMUNITIES_COOKIE
      ? likedCommunities?.add(slug)
      : dislikedCommunities?.add(slug);

    setCookie(LIKED_COMMUNITIES_COOKIE, Array.from(likedCommunities).join(','));
    setCookie(
      DISLIKED_COMMUNITIES_COOKIE,
      Array.from(dislikedCommunities).join(',')
    );
    setLikedCommunities(new Set(likedCommunities));
    setDislikedCommunities(new Set(dislikedCommunities));
    fetch();
  };

  const normalized = requestInfo?.normalized;

  return (
    <>
      <Dialog
        onRequestClose={toggle}
        closeOnOutsideClick
        open={isOpen}
        trayOnly
      >
        <div
          onClick={toggle}
          className='flex w-full cursor-pointer justify-center rounded-t-2xl border-b border-slate-lighter-90 py-4'
        >
          <Dropdown className='mr-2 rotate-180' />
          <div className='font-t-xs-azo text-viridian-base'>
            Hide recommendations
          </div>
        </div>

        <TabbedContent
          tabs={[
            `Recommendations (${normalized?.recommendations?.length || 0})`,
            `Favorites (${normalized?.liked?.length || 0})`,
            `Not a good fit (${normalized?.disliked?.length || 0})`,
          ]}
          eventProps={[
            {
              location: 'recommendationModule',
              text: 'Recommendations',
            },
            {
              location: 'recommendationModule',
              text: 'Favorites',
            },
            {
              location: 'recommendationModule',
              text: 'Not a good fit',
            },
          ]}
          wrapperClass='min-h-[490px]'
          headerClass='!bg-white mb-4 border-t-0 border-b border-slate-lighter-90'
          textClass='!py-4'
          content={[
            requestInfo?.isLoading ? (
              <div className='flex min-h-[400px] items-center justify-center'>
                <Spinner />
              </div>
            ) : normalized?.recommendations?.length ? (
              <Carousel
                className='mx-4'
                itemsQty={normalized?.length}
                scrollbar
                hideChevron
                key='Recommendations'
              >
                {normalized?.recommendations?.map((community: Community) => (
                  <EntityTile
                    key={community.id}
                    entity={community}
                    hideDescription
                    eventProps={{
                      location: 'recommendationModule',
                    }}
                    intent='recs'
                    like={() =>
                      updateCookie(community.id, 'sly_liked_communities')
                    }
                    dislike={() =>
                      updateCookie(community.id, 'sly_disliked_communities')
                    }
                    liked={likedCommunities.has(community.id)}
                    disliked={dislikedCommunities.has(community.id)}
                  />
                ))}
              </Carousel>
            ) : (
              <div className='flex h-full w-full items-center justify-center'>
                <div className='text-center'>
                  <h3 className='font-t-s-azo'>No recommendations</h3>
                  <p className='font-t-xs-azo'>
                    We couldn&apos;t find any recommendations for you. Try
                    changing your preferences.
                  </p>
                </div>
              </div>
            ),

            requestInfo?.isLoading ? (
              <div className='flex min-h-[400px] items-center justify-center'>
                <Spinner />
              </div>
            ) : normalized?.liked?.length ? (
              <Carousel
                className='mx-4'
                itemsQty={normalized?.length}
                hideChevron
                scrollbar
                key='Favorites'
              >
                {normalized?.liked?.map((community: Community) => (
                  <EntityTile
                    key={community.id}
                    entity={community}
                    eventProps={{
                      location: 'recommendationModule',
                    }}
                    hideDescription
                    intent='recs'
                    like={() =>
                      updateCookie(community.id, 'sly_liked_communities')
                    }
                    dislike={() =>
                      updateCookie(community.id, 'sly_disliked_communities')
                    }
                    liked={likedCommunities.has(community.id)}
                    disliked={dislikedCommunities.has(community.id)}
                  />
                ))}
              </Carousel>
            ) : (
              <div className='flex h-full w-full items-center justify-center'>
                <div className='text-center'>
                  <h3 className='font-t-s-azo'>No favorites</h3>
                  <p className='font-t-xs-azo'>
                    You haven&apos;t liked any communities yet. Try exploring
                    more communities.
                  </p>
                </div>
              </div>
            ),
            requestInfo?.isLoading ? (
              <div className='flex min-h-[400px] items-center justify-center'>
                <Spinner />
              </div>
            ) : normalized?.disliked?.length ? (
              <Carousel
                itemsQty={normalized?.length}
                hideChevron
                className='mx-4'
                scrollbar
                innerClassName='overflow-x-auto'
                key='Not a good fit'
              >
                {normalized?.disliked?.map((community: Community) => (
                  <EntityTile
                    key={community.id}
                    entity={community}
                    eventProps={{
                      location: 'recommendationModule',
                    }}
                    hideDescription
                    intent='recs'
                    like={() =>
                      updateCookie(community.id, 'sly_liked_communities')
                    }
                    dislike={() =>
                      updateCookie(community.id, 'sly_disliked_communities')
                    }
                    liked={likedCommunities.has(community.id)}
                    disliked={dislikedCommunities.has(community.id)}
                  />
                ))}
              </Carousel>
            ) : (
              <div className='flex h-full w-full items-center justify-center'>
                <div className='text-center'>
                  <h3 className='font-t-s-azo'>No dislikes</h3>
                  <p className='font-t-xs-azo'>
                    You haven&apos;t disliked any communities yet. Try exploring
                    more communities.
                  </p>
                </div>
              </div>
            ),
          ]}
        />
      </Dialog>
      {/* Sticky footer */}
      {isVisible && (
        <div
          onClick={toggle}
          className='fixed bottom-0 left-0 z-[999] flex w-full cursor-pointer justify-center rounded-t-2xl border-t border-slate-lighter-90 bg-viridian-lighter-90 py-4'
        >
          <Dropdown className='mr-2' />
          <div className=' text-viridian-base'>Show recommendations</div>
        </div>
      )}
    </>
  );
};

export default Recs;
