/** @jsxImportSource react */
import PostConversionGreetingForm from '@react/cta/components/PostConversionGreetingForm';
import { Link } from '@react/system';
import { func, object } from 'proptypes';
import React from 'react';

const PostConversionGreeting = ({ community, toggleModal }) => {
  return (
    <PostConversionGreetingForm
      hasBox={false}
      community={community}
      onReturnClick={toggleModal}
      heading='Request sent! One of our Seniorly Local Advisors will reach out to assist you.'
      description='Questions? You can contact us by phone or email:'
    >
      <div
        className='mb-l flex flex-col gap-xs'
        data-tp-id='gen-div-3f821d37-7f14-44fe-a791-cba99625c312'
      >
        <Link
          href='mailto:familysupport@seniorly.com'
          data-tp-id='gen-Link-f77b9bd8-fb17-48ae-81ae-19144d84eaf8'
        >
          familysupport@seniorly.com
        </Link>
        <Link
          href='tel:8775662778'
          data-tp-id='gen-Link-c3ef5158-f39b-42c4-9469-1048d84e080a'
        >
          (877) 566-2778
        </Link>
      </div>
    </PostConversionGreetingForm>
  );
};

PostConversionGreeting.propTypes = {
  toggleModal: func.isRequired,
  community: object.isRequired,
};

export default PostConversionGreeting;
