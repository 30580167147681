/** @jsxImportSource react */
import { phoneFormatter, phoneParser } from '@common/helpers/phone';
import { GwizzyCommunity } from '@common/helpers/transformGwizzyCommunity';
import {
  composeValidators,
  email,
  required,
  usPhone,
} from '@react/form/helpers/validation';
import {
  TocComponent,
  TocEmailComponent,
} from '@react/gwizzy/components/GwizzyTocComponent';
import TipComponent from '@react/gwizzy/components/TipComponent';
import {
  ACTIONS as actions,
  CONVERSION_FIELDS as fields,
  CONVERSION_STEPS_MAP as steps,
  HOUSING_OPTIONS,
} from '@react/gwizzy/constants';

import { shouldAskResNameInPreConv } from '../helpers/utils';
import { FormSchema } from '../types';

export function getAASchema({
  community,
  isReadOnlyEmail = false,
  isChatMode = false,
  isResidentNameQuestionRequired,
}: {
  community?: GwizzyCommunity;
  showLocationStep?: boolean;
  isReadOnlyEmail?: boolean;
  isChatMode?: boolean;
  isResidentNameQuestionRequired?: boolean;
}): FormSchema {
  const submitText = community ? 'Send me pricing' : 'Get recommendations';
  const shouldCaptureEmailLead = true;

  const hasRadio = !isChatMode;

  return {
    [steps.housingOptions]: {
      form: {
        getQuestion: () => 'What are you interested in learning more about?',
        formLabel: 'Choose as many as you like',
        getActions: () => ({
          onNext: {
            goTo: ({ housingOptions }) => {
              const isBuyingOrSelling = housingOptions?.some?.((i: string) =>
                [HOUSING_OPTIONS.buy, HOUSING_OPTIONS.sell].includes(i)
              );
              return isBuyingOrSelling
                ? steps.planningConsultation
                : steps.name;
            },
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.housingOptions.options,
            name: fields.housingOptions.name,
            type: 'multiChoice',
            multiChoice: true,
            validate: required,
            hasRadio,
          },
        ],
      },
    },
    [steps.planningConsultation]: {
      form: {
        getQuestion: () =>
          'Are you interested in a 15 minute planning consultation, at no cost to you?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.moveTimeline,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.planningConsultation.options,
            name: fields.planningConsultation.name,
            type: 'singleChoice',
            multiChoice: false,
            validate: required,
            hasRadio,
          },
        ],
      },
    },
    [steps.moveTimeline]: {
      form: {
        getQuestion: () => 'What is your move timeline?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.name,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.timing.options,
            name: fields.timing.name,
            type: 'singleChoice',
            multiChoice: false,
            validate: required,
            hasRadio,
          },
        ],
      },
    },
    [steps.name]: {
      form: {
        getQuestion: () => 'What is your first and last name?',
        formLabel:
          'Thank you for sharing! Please enter your contact info below and a real estate specialist will get in touch about your move.',
        getActions: () => ({
          onNext: {
            goTo: () => steps.email,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
          },
        }),
        fieldWrapperClassName: isChatMode
          ? 'space-y-2 mb-4'
          : 'flex flex-col md:flex-row gap-3 md:child:w-1/2',
        fields: [
          {
            name: 'firstName',
            type: 'text',
            label: 'First name',
            placeholder: 'First name',
            validate: required,
          },
          {
            name: 'lastName',
            type: 'text',
            label: 'Last name',
            placeholder: 'Last name',
            validate: required,
          },
        ],
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            Let us know your real name. We are real people helping real people
          </TipComponent>
        ),
      },
    },
    [steps.email]: {
      form: {
        getQuestion: () => 'What is your email address?',
        getActions: () => ({
          onNext: {
            goTo: () => steps.phone,
            type: actions.setActivePage,
            text: 'Continue',
            variant: 'primary',
            asyncHandlerProps: {
              shouldSendUUIDAction: shouldCaptureEmailLead,
            },
            validations: [{ name: 'email', error: 'Invalid email detected' }],
          },
        }),
        fields: [
          {
            name: 'email',
            type: 'email',
            label: 'Email address',
            placeholder: 'Email address',
            validate: composeValidators(email, required),
            readOnly: isReadOnlyEmail,
          },
        ],
        footer: {
          FormFooter: () => <TocEmailComponent />,
        },
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            We will send you pricing information over email.
          </TipComponent>
        ),
      },
    },
    [steps.phone]: {
      form: {
        getQuestion: () => 'What is your phone number? ',
        getActions: () => ({
          onNext: {
            goTo: () => steps.homeSaleInterest,
            getType: () => actions.setActivePage,
            text: submitText,
            variant: 'primary',
            asyncHandlerProps: {
              shouldSendUUIDAction: true,
              shouldCreateUser: true,
            },
            validations: [
              { name: 'phone', error: 'Fake phone number detected' },
            ],
          },
        }),
        fields: [
          {
            name: 'phone',
            type: 'text',
            label: 'Phone number',
            validate: composeValidators(usPhone, required),
            parse: phoneParser,
            format: phoneFormatter,
          },
        ],
        footer: {
          FormFooter: () => <TocComponent submitText={submitText} />,
        },
      },
      tooltip: {
        ToolTipComponent: () => (
          <TipComponent>
            In case we need more information about you we will reach out by
            phone
          </TipComponent>
        ),
      },
    },
    [steps.homeSaleInterest]: {
      form: {
        getQuestion: () => 'Are you planning to sell your home?',
        formLabel:
          'Over 70% of older adults will use funds from the sale of a home to help fund the transition to senior living.',
        getActions: () => ({
          onNext: {
            goTo: () => steps.residentName,
            getType: (values) => {
              return shouldAskResNameInPreConv(
                values,
                isResidentNameQuestionRequired
              )
                ? actions.setActivePage
                : actions.submit;
            },
            getText: (values) => {
              return shouldAskResNameInPreConv(
                values,
                isResidentNameQuestionRequired
              )
                ? 'Continue'
                : 'Submit';
            },
            variant: 'primary',
          },
        }),
        fields: [
          {
            getOptions: () => fields.homeSaleInterest.options,
            name: fields.homeSaleInterest.name,
            type: 'boxChoice',
            validate: required,
            hasRadio,
          },
        ],
      },
    },
    [steps.residentName]: {
      form: {
        getQuestion: () =>
          'What is the name of the person you are searching for?',
        getActions: () => {
          return {
            onNext: {
              type: actions.submit,
              text: 'Submit',
              variant: 'primary',
            },
          };
        },
        fields: [
          {
            name: 'residentName',
            type: 'text',
            label: 'Resident Name',
            validate: required,
          },
        ],
      },
    },
  };
}
