/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import { getDisclosureFieldsByState } from '@react/gwizzy/helpers/utils';
import { useQuery } from '@react/services/api';
import Spinner from '@react/shared/components/Spinner';
import { delay } from '@react/shared/helpers';
import { Button } from '@react/system';
import { WIZARD_STEP_COMPLETED } from '@sly/core/constants/actionTypes';
import { stateAbbr } from '@sly/core/constants/geo';
import { Checkmark } from '@sly/icons/react';
import { useState } from 'react';
import { isBrowser } from 'sly/config';

import ArizonaDisclosure from './ArizonaDisclosure';
import ColorodoDisclosure from './ColorodoDisclosure';
import OklahomaDisclosure from './OklahomeDisclosure';

export default function Disclosure({
  isChatMode = false,
  handleConfirmation,
  state,
}: {
  handleConfirmation: () => Promise<void>;
  isChatMode?: boolean;
  state: string;
}) {
  const [isPending, setIsPending] = useState(false);
  const createAction = useQuery('createUuidAction');
  const { acknowledgementField, disclosureTextField } =
    getDisclosureFieldsByState(state);

  const handleACK = async (value: boolean) => {
    setIsPending(true);
    try {
      await createAction({
        type: 'UUIDAction',
        attributes: {
          actionType: WIZARD_STEP_COMPLETED,
          actionPage: isBrowser ? location.pathname : '',
          actionInfo: {
            data: {
              [acknowledgementField]: value,
              [disclosureTextField]:
                document?.getElementById('disclosure-text')?.textContent,
            },
            stepName: 'wizardPostConversionInfo',
            wizardName: 'gwizzy',
          },
        },
      });
      await delay(4000);
    } catch (error) {
      console.error(error);
    }
    await handleConfirmation();
    setIsPending(false);
  };
  return (
    <>
      <div
        className={clsxm(
          'bg-white py-6 ',
          isChatMode ? 'px-2' : 'px-4 md:px-8'
        )}
      >
        {!isPending && (
          <div className='flex h-full min-h-[300px] max-w-full flex-col justify-center'>
            <div className='font-t-m mb-6'>
              Before we can get you in touch with the community please read:
            </div>
            <div
              className='font-b-s h-40 overflow-auto border p-2'
              id='disclosure-text'
            >
              {state === stateAbbr.Arizona && <ArizonaDisclosure />}
              {state === stateAbbr.Colorado && <ColorodoDisclosure />}
              {state === stateAbbr.Oklahoma && <OklahomaDisclosure />}
            </div>

            <div className='my-6 bg-green-lighter-90 p-4'>
              <div className='flex gap-4'>
                <div>
                  <Checkmark color='text-viridian-base' />
                </div>
                <div className='font-b-m'>Our service is always free</div>
              </div>
              <div className='flex gap-4'>
                <div>
                  <Checkmark color='text-viridian-base' />
                </div>
                <div className='font-b-m'>
                  We are compensated by the community only if you move
                </div>
              </div>
            </div>

            <div className='flex flex-col items-center justify-center gap-3'>
              <Button
                variant='primary'
                onClick={() => {
                  handleACK(true);
                }}
                className='!whitespace-normal'
                disabled={isPending}
                type='button'
              >
                I acknowledge
              </Button>
              <Button
                variant='outlined'
                className='!whitespace-normal border-slate-lighter-90'
                palette='slate'
                disabled={isPending}
                onClick={() => {
                  handleACK(false);
                }}
                type='button'
              >
                I do not acknowledge
              </Button>
            </div>
          </div>
        )}
        {isPending && (
          <div className='flex h-full min-h-[300px] max-w-full flex-col items-center justify-center gap-3 rounded-md bg-white px-6 pb-6'>
            <Spinner />
            <div>Please wait while we submit your request</div>
          </div>
        )}
      </div>
    </>
  );
}
