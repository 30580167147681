import {
  GeoGuide,
  GeoGuideAll,
  GwizzyPricingThreshold
} from '@sly/core/api/types';
import { TOCS } from '@sly/core/constants/filters';

export function getGwizzyPricingOptionForToc(
  geoGuides: GeoGuideAll | undefined,
  toc: string
) {
  if (!geoGuides || !toc) {
    return null;
  }

  const currentToc = TOCS[toc as keyof typeof TOCS];

  if (!currentToc) {
    return null;
  }

  const currentGeoGuide: GeoGuide | null = Object.values(geoGuides).find(
    (g: GeoGuide) => g?.caretype === currentToc.apiLabel
  );

  if (!currentGeoGuide) {
    return null;
  }

  const { info } = currentGeoGuide;

  const { gwizzyPricingOptions } = info || {};

  if (
    !(Array.isArray(gwizzyPricingOptions) && gwizzyPricingOptions.length > 0)
  ) {
    return null;
  }

  return gwizzyPricingOptions;
}

export function getIsValidCustomBudgetOption(
  options: GwizzyPricingThreshold[] | null | undefined
): options is GwizzyPricingThreshold[] {
  return Array.isArray(options) && options.length > 0;
}

export function getGwizzyCustomOptions<T>(
  gwizzyPricingOptions: GwizzyPricingThreshold[] | null | undefined,
  fallbackValue: T
) {
  if (!getIsValidCustomBudgetOption(gwizzyPricingOptions)) {
    return fallbackValue;
  }

  return gwizzyPricingOptions;
}

interface GwizzyBudgetOptions {
  label: string;
  value: string;
  supplementalIncomeRequired: boolean | null;
}

export function getIsSupplementalIncomeRequired(
  options: GwizzyBudgetOptions[],
  value: string
) {
  const selectedOption = options.find(option => option.value === value);
  return selectedOption?.supplementalIncomeRequired;
}
