/** @jsxImportSource react */
import PostConversionModal from '@react/cta/components/PostConversionModal';
import CtaButton from '@react/cta/CtaButton';
import useCta from '@react/cta/hooks/useCta';
import useGwizzyCta from '@react/cta/hooks/useGwizzyCta';
import WizardDialog from '@react/cta/WizardDialog';
import AdvisorConfirmation from '@react/gwizzy/components/AdvisorConfirmation';
import ConversionWizard from '@react/gwizzy/components/ConversionWizard';
import D2CConfirmation from '@react/gwizzy/components/D2CConfirmation';
import Disclosure from '@react/gwizzy/components/Disclosure';
import PostConversionWizard from '@react/gwizzy/components/PostConversionWizard';
import RecommendedCommunitiesModal from '@react/gwizzy/components/RecommendedCommunitiesModal';
import { getClientMetaFromConversionData } from '@react/gwizzy/helpers/utils';
import { Button } from '@react/system';
import { Checkmark } from '@sly/icons/react';
import { useCallback } from 'react';

export default function GwizzyCta(props) {
  const {
    ctaProps,
    buttonStyle = {},
    eventProps,
    className,
    mode,
    CustomSuccessButton,
    isOpenByDefault,
    params,
    showLocationStep,
    gwizzyCommunity,
  } = props;

  const {
    variant = 'primary',
    palette = 'viridian',
    text: ctaButtonText,
  } = ctaProps;

  const isD2C = gwizzyCommunity?.isD2C;

  const {
    agent,
    pricingRequested,
    completedAssessment,
    modalOpened,
    completedAssessmentFlow,
    isClientSide,
    handleCompletedAssementModalClose,
    createProfileContactActionBySlugs,
    getPricingRequestedListBySlugs,
    getPricingRequestedBySlug,
    createProfileContactActionBySlug,
  } = useCta({
    community: gwizzyCommunity,
    mode,
    isOpenByDefault,
    params,
  });

  const {
    isShowingConversion,
    isShowingPostConversion,
    isShowingAdvisorModal,
    isShowingRCModal,
    isShowingD2CConfirmationModal,
    isShowingDiscloureModal,
    actions,
    state: {
      conversionData,
      isMobile,
      recommendedCommunities,
      recommendedReasons,
      referredCommunities,
      referredAgent,
      enabledDisclosureState,
    },
    contactInfo,
  } = useGwizzyCta({
    ...props,
    // refetchUUIDActions,
    isOpenByDefault: isOpenByDefault && !completedAssessment,
    isD2C,
  });

  const ButtonComponent = useCallback(() => {
    if (pricingRequested) {
      if (CustomSuccessButton) {
        return <CustomSuccessButton />;
      }
      return (
        <Button
          className='grid h-max w-full cursor-[unset] grid-cols-[min-content_min-content] justify-center gap-s'
          disabled={pricingRequested}
          data-tp-id='gen-Button-33aeead9-b0e4-4bea-b8b1-0cea66e463a3'
        >
          <Checkmark />
          <div data-tp-id='gen-div-d69d8f93-9332-400a-9634-11fcbf05809b'>
            Pricing Requested
          </div>
        </Button>
      );
    }
    if (completedAssessment && isClientSide) {
      return (
        <>
          <CtaButton
            variant={variant}
            palette={palette}
            textAlign='center'
            onClick={completedAssessmentFlow}
            className={className}
            {...buttonStyle}
          >
            {ctaButtonText || 'Get access'}
          </CtaButton>
          {modalOpened && (
            <PostConversionModal
              agent={agent}
              community={gwizzyCommunity}
              modalOpened={modalOpened}
              toggleModal={
                isOpenByDefault
                  ? completedAssessmentFlow
                  : handleCompletedAssementModalClose
              }
            />
          )}
        </>
      );
    }
    return (
      <CtaButton
        onClick={(e) => {
          e.preventDefault();
          actions.conversionStart();
        }}
        palette={palette}
        variant={variant}
        {...buttonStyle}
        eventProps={eventProps}
        className={className}
      >
        {ctaButtonText}
      </CtaButton>
    );
  }, [
    CustomSuccessButton,
    actions,
    agent,
    buttonStyle,
    className,
    completedAssessment,
    completedAssessmentFlow,
    ctaButtonText,
    eventProps,
    gwizzyCommunity,
    handleCompletedAssementModalClose,
    isClientSide,
    isOpenByDefault,
    modalOpened,
    palette,
    pricingRequested,
    variant,
  ]);

  return (
    <>
      <ButtonComponent />
      {isShowingConversion && (
        <WizardDialog
          handleClose={() => actions.handleClose()}
          isOpen={isShowingConversion}
        >
          <div
            className='bg-slate-lighter-95'
            data-pw='gwizzy-conversion-wizard'
          >
            <ConversionWizard
              community={gwizzyCommunity}
              ctaActions={actions}
              eventProps={eventProps}
              contactInfo={contactInfo}
              showLocationStep={showLocationStep}
              mode={mode}
              enabledDisclosureState={enabledDisclosureState}
            />
          </div>
        </WizardDialog>
      )}
      {isShowingRCModal && (
        <WizardDialog
          isOpen={isShowingRCModal}
          handleClose={async () => await actions.rcDone()}
        >
          <div className='bg-slate-lighter-95'>
            <RecommendedCommunitiesModal
              getPricingRequestedListBySlugs={getPricingRequestedListBySlugs}
              getPricingRequestedBySlug={getPricingRequestedBySlug}
              createProfileContactActionBySlug={
                createProfileContactActionBySlug
              }
              recommendedCommunities={recommendedCommunities}
              recommendedReasons={recommendedReasons}
              createProfileContactActionBySlugs={
                createProfileContactActionBySlugs
              }
              eventProps={eventProps}
              handleContinue={actions.rcDone}
            />
          </div>
        </WizardDialog>
      )}
      {isShowingD2CConfirmationModal && (
        <WizardDialog
          isOpen={isShowingD2CConfirmationModal}
          handleClose={() => actions.d2cConfirmationDone()}
        >
          <div className='bg-slate-lighter-95'>
            <D2CConfirmation
              handleConfirmation={actions.d2cConfirmationDone}
              referredCommunities={referredCommunities}
              referredAgent={referredAgent}
              convertedCommunity={gwizzyCommunity}
            />
          </div>
        </WizardDialog>
      )}
      {isShowingAdvisorModal && (
        <WizardDialog
          isOpen={isShowingAdvisorModal}
          handleClose={() => actions.advisorConfirmationDone()}
        >
          <AdvisorConfirmation
            handleConfirmation={actions.advisorConfirmationDone}
            partnerAgent={referredAgent}
            clientMeta={getClientMetaFromConversionData({
              agent: referredAgent,
              conversionData,
              isMobile,
            })}
            variant={gwizzyCommunity ? 'pricing' : 'consultation'}
          />
        </WizardDialog>
      )}
      {isShowingPostConversion && (
        <WizardDialog
          isOpen={isShowingPostConversion}
          handleClose={() => actions.handleClose()}
        >
          <PostConversionWizard
            onDone={actions.postConversionDone}
            clientMeta={getClientMetaFromConversionData({
              agent: referredAgent,
              conversionData,
              isMobile,
            })}
            community={gwizzyCommunity}
          />
        </WizardDialog>
      )}
      {isShowingDiscloureModal && (
        <WizardDialog
          isOpen={isShowingDiscloureModal}
          handleClose={() => actions.handleClose()}
        >
          <Disclosure
            state={enabledDisclosureState}
            handleConfirmation={actions.handleConfirmationScreen}
          />
        </WizardDialog>
      )}
    </>
  );
}
