/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */
import toUpper from 'lodash/toUpper';
import { parse, stringify } from 'query-string';
import { constructSearchPathWithPreference } from '@react/search/helpers';
import { titleize } from '@common/helpers/strings';
import {
  caStatePaths,
  stateAbbr,
  stateNames,
  usStatePaths,
  usStates
} from '@sly/core/constants/geo';
import { NURSING_HOME } from '@sly/core/constants/tags';

export const tocPaths = toc => {
  if (toc && toc.length > 0) {
    switch (toc[0]) {
      case 'Assisted Living':
        return {
          path: '/assisted-living',
          label: 'Assisted Living',
          value: 'assisted-living'
        };
      case 'Independent Living':
        return {
          path: '/independent-living',
          label: 'Independent Living',
          value: 'independent-living'
        };
      case 'Memory Care':
        return {
          path: '/memory-care',
          label: 'Memory Care',
          value: 'memory-care'
        };
      case 'Board and Care Home':
      case 'Board And Care Home':
        return {
          path: '/board-and-care-home',
          label: 'Board and Care Home',
          value: 'board-and-care-home'
        };
      case 'Care Home':
        return {
          path: '/care-home',
          label: 'Care Home',
          value: 'care-home'
        };
      case 'Continuing Care Retirement Community(CCRC)':
      case 'Continuing Care Retirement Community':
        return {
          path: '/continuing-care-retirement-community',
          label: 'Continuing Care Retirement Community(CCRC)',
          value: 'continuing-care-retirement-community'
        };
      case 'Skilled Nursing Facility':
        return {
          path: '/skilled-nursing-facility',
          label: NURSING_HOME,
          value: 'skilled-nursing-facility'
        };
      case 'Active Adult Communities (55+)':
      case 'Active Adult (55+)':
      case 'Active Adult':
        return {
          path: '/active-adult',
          label: 'Active Adult Communities (55+)',
          value: 'active-adult'
        };
      default:
        return {
          path: '/senior-living',
          label: 'Senior Living',
          value: 'senior-living'
        };
    }
  } else {
    return {
      path: '/senior-living',
      label: 'Senior Living',
      value: 'senior-living'
    };
  }
};

const tocGuidePaths = toc => {
  if (toc && toc.length > 0) {
    switch (toc[0]) {
      case 'Assisted Living':
        return {
          path: '/assisted-living-guide',
          label: 'Assisted Living Guide'
        };
      case 'Memory Care':
        return {
          path: '/memory-care-guide',
          label: 'Memory Care Guide'
        };
      default:
        return {
          path: '/nursing-homes-guide',
          label: 'Nursing Homes Guide'
        };
    }
  } else {
    return {
      path: '/nursing-homes-guide',
      label: 'Nursing Homes Guide'
    };
  }
};

const regionStateMap = {
  Northeast: ['CT', 'ME', 'MA', 'NH', 'RI', 'VT', 'NJ', 'NY', 'PA'],
  Midwest: [
    'IL',
    'IN',
    'MI',
    'OH',
    'WI',
    'IA',
    'KS',
    'MN',
    'MO',
    'NE',
    'ND',
    'SD'
  ],
  South: ['DE', 'FL', 'GA', 'MD', 'NC', 'SC', 'VA', 'DC', 'WV'],
  Southeast: ['AL', 'KY', 'MS', 'TN'],
  Southwest: ['AR', 'LA', 'OK', 'TX'],
  'Mountain West': ['AZ', 'CO', 'ID', 'MT', 'NV', 'NM', 'PR', 'UT', 'VI', 'WY'],
  'Pacific West': ['AS', 'GU', 'AK', 'CA', 'HI', 'OR', 'WA']
};

export const stateRegionMap = Object.entries(regionStateMap).reduce(
  (res, [region, states]) => {
    states.forEach(state => {
      res[state] = region;
    });
    return res;
  },
  {}
);

// Converts SanFrancisco to san-francisco
export const snakeCase = string => {
  return string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('-');
};

export const urlize = inString =>
  decodeURI(inString)
    .trim()
    .toLowerCase()
    .replace(/_/g, '-')
    .replace(/[^\w\s-]+/g, '')
    .replace(/[\s-]+/g, ' ')
    .replace(/\s/g, '-');

// global.stateData = {
//   names: stateNames,
//   slugs: Object.entries(stateNames).reduce(
//     (res, [key, name]) => ((res[key] = urlize(name)), res),
//     {}
//   ),
//   stateRegionMap,
//   regionStateMap,
//   regionSlugs: Object.keys(regionStateMap).reduce(
//     (res, name) => ((res[name] = urlize(name)), res),
//     {}
//   ),
// };

export const getBreadCrumbsForCommunity = ({ name, care, address, slug }) => {
  const tocBc = tocPaths(care);

  // TODO: use react router generated paths once router wiring is complete
  return [
    {
      path: '/',
      label: 'Home'
    },
    {
      ...tocBc,
      eventProps: {
        location: 'breadcrumb',
        communitySlug: slug
      }
    },
    {
      // path: `${tocBc.path}/${urlize(stateNames[address.state])}`,
      path: constructSearchPathWithPreference({
        currentPath: `${tocBc.path}/${urlize(stateNames[address.state])}`
      }).modifiedPath,
      label: stateNames[address.state],
      eventProps: {
        location: 'breadcrumb',
        communitySlug: slug
      }
    },
    {
      // path: `${tocBc.path}/${urlize(stateNames[address.state])}/${urlize(address.city)}`,
      path: constructSearchPathWithPreference({
        currentPath: `${tocBc.path}/${urlize(
          stateNames[address.state]
        )}/${urlize(address.city)}`
      }).modifiedPath,
      label: address.city,
      eventProps: {
        location: 'breadcrumb',
        communitySlug: slug
      }
    },
    {
      // path: `${tocBc.path}/${urlize(stateNames[address.state])}/${urlize(address.city)}/${slug}`,
      path: `${tocBc.path}/${urlize(stateNames[address.state])}/${urlize(
        address.city
      )}/${slug}`,
      label: name,
      eventProps: {
        location: 'breadcrumb',
        communitySlug: slug
      }
    }
  ];
};

export const getBreadCrumbsForListing = ({ name }) => {
  // TODO: use react router generated paths once router wiring is complete
  return [
    {
      path: '/',
      label: 'Home'
    },
    {
      path: '#',
      label: 'plus'
    },
    {
      path: '#',
      label: name
    }
  ];
};

export const getBreadCrumbsForLocation = (
  { toc, state, city },
  noHomeAndToc,
  eventLocation = null
) => {
  const tocBc = tocPaths([titleize(toc)]);
  // TODO: use react router generated paths once router wiring is complete
  let baseBcs = [
    {
      path: '/',
      label: 'Home',
      eventProps: {
        location: eventLocation
      }
    }
  ];
  // TODO A better job
  if (tocBc) {
    if (!noHomeAndToc) {
      baseBcs.push(tocBc);
    } else {
      baseBcs = [];
    }
  } else {
    // Safety
    return baseBcs;
  }

  if (state) {
    baseBcs.push({
      path: `${tocBc.path}/${state}`,
      label: titleize(state)
    });
  } else {
    return baseBcs;
  }

  if (city) {
    baseBcs.push({
      path: `${tocBc.path}/${state}/${city}`,
      label: titleize(city)
    });
  }

  return baseBcs;
};

const agentsPath = '/agents';
export const getBreadCrumbsForAgent = ({ name, state, city, id }) => {
  const baseBcs = [
    {
      path: '/',
      label: 'Home'
    },
    {
      path: agentsPath,
      label: 'Agents'
    }
  ];

  if (state) {
    const region = stateRegionMap[state];
    baseBcs.push({
      path: `${agentsPath}/${urlize(region)}`,
      label: region
    });
    if (city) {
      baseBcs.push({
        path: `${agentsPath}/${urlize(region)}/${urlize(city)}-${urlize(
          state
        )}`,
        label: `${city}, ${state}`
      });
      if (name) {
        baseBcs.push({
          path: `${agentsPath}/${urlize(region)}/${urlize(city)}-${urlize(
            state
          )}/${id}`,
          label: name
        });
      }
    } else {
      return baseBcs;
    }
  } else {
    return baseBcs;
  }
  return baseBcs;
};

export const getBreadCrumbsForGuides = ({ toc, region, regionName }) => {
  const tocBc = tocGuidePaths([titleize(toc)]);
  // TODO: use react router generated paths once router wiring is complete
  const baseBcs = [
    {
      path: '/',
      label: 'Home'
    }
  ];
  // TODO A better job
  if (tocBc) {
    baseBcs.push(tocBc);
  } else {
    // Safety
    return baseBcs;
  }

  if (region) {
    baseBcs.push({
      path: `${tocBc.path}/${region}`,
      label: regionName
    });
  } else {
    return baseBcs;
  }

  return baseBcs;
};

export const getAgentUrl = ({ id, address }) => {
  const { state, city } = address;
  const region = stateRegionMap[state];
  return `${agentsPath}/${urlize(region)}/${urlize(city)}-${urlize(
    state
  )}/${id}`;
};

export const getCitySearchUrl = ({ propInfo, address }) => {
  const tocBc = tocPaths(propInfo.typeCare);
  return `${tocBc.path}/${urlize(stateNames[address.state])}/${
    address.city ? urlize(address.city) : ''
  }`;
};

export const getOrigin = () => {
  if (!window) {
    return '';
  }
  // window.location.origin is not present in ie, opera and some mobile browsers
  if (!window.location.origin) {
    const port = window.location.port ? `:${window.location.port}` : '';
    window.location.origin = `${window.location.protocol}//${window.location.hostname}${port}`;
  }
  return window.location.origin;
};

export const objectToURLQueryParams = (obj, options) => stringify(obj, options);
export const parseURLQueryParams = obj => parse(obj, { arrayFormat: 'comma' });

// return the two or three letter upper cased abbreviation for passed in states,
// if the value passed in is already an abbrevation, return the same
// if state not found, return empty string
export const getStateAbbr = state => {
  if (state) {
    // stateNames is a constant Map that has abbreviation -> fullName for all supported states
    const ucState = toUpper(state);
    if (Object.prototype.hasOwnProperty.call(stateNames, ucState)) {
      return ucState;
    }
    const st = titleize(state);
    return stateAbbr[st];
  }
  return '';
};

export const getCannonicalStatePath = state => {
  if (state) {
    // stateNames is a constant Map that has abbreviation -> fullName for all supported states
    const ucState = toUpper(state);
    if (Object.prototype.hasOwnProperty.call(stateNames, ucState)) {
      return urlize(stateNames[ucState]);
    }
    return urlize(titleize(state));
  }
  return state;
};

export const removeQueryParamFromURL = (key, sourceURL) => {
  const [path, queryStringStr] = sourceURL.split('?');
  const queryStringsObj = parse(queryStringStr);
  queryStringsObj[key] = undefined;
  const newQs = stringify(queryStringsObj);
  let newPath = path;
  if (newQs !== '') {
    newPath = `${path}?${newQs}`;
  }
  return newPath;
};

export const removeQueryParamNext = (router, ...paramsToDelete) => {
  const { pathname, query } = router;
  const params = new URLSearchParams(query);
  paramsToDelete.forEach(param => {
    params.delete(param);
  });
  router.replace({ pathname, query: params.toString() }, undefined, {
    shallow: true
  });
};

export const replaceLastSegment = (orig, id) => {
  if (!id) {
    // remove the last segment
    return orig.replace(/\/[^/]+\/?$/gm, '');
  }
  // replace it
  return orig.replace(/[^/]+\/?$/gm, id);
};

export const getLastSegment = url => {
  const [last] = url.match(/([^/]+)\/?$/gm);
  return last;
};

export const generateSearchUrl = (toc, address) => {
  const tocBc = tocPaths(toc);
  return `${tocBc.path}/${urlize(stateNames[address.state])}/${urlize(
    address.city
  )}`;
};

export const generateCityPathSearchUrl = address => {
  return `${urlize(stateNames[address.state])}/${urlize(address.city)}`;
};

export const isInternationalPath = path => {
  const pathParts = path.split('/');
  if (pathParts.length > 1 && pathParts[1] === 'dashboard') {
    return false;
  }
  if (pathParts.length > 1 && pathParts[1] === 'partners') {
    return false;
  }
  if (pathParts.length > 1 && pathParts[1] === 'agents') {
    return false;
  }
  if (pathParts.length > 1 && pathParts[1] === 'plus') {
    return false;
  }
  // check if 1st part is care-home
  if (pathParts.length > 2 && pathParts[1] === 'care-home') {
    return true;
  }
  // check if path is not us state path

  if (pathParts.length > 2) {
    const statePart = pathParts[2].toLowerCase();
    if (
      usStatePaths.indexOf(statePart) === -1 &&
      usStates.filter(e => {
        return (
          e.abbr.toLowerCase() === statePart ||
          urlize(e.name.toLowerCase()) === statePart
        );
      }).length === 0
    ) {
      return true;
    }
  }

  return false;
};

export const isCanadaPath = path => {
  const pathParts = path.split('/');
  // check if 1st part is care-home
  if (pathParts.length > 2 && caStatePaths.indexOf(pathParts[2]) > -1) {
    return true;
  }

  return false;
};

export const getCitySearchUrlForListing = ({ care, address }) => {
  const tocBc = tocPaths(care);
  return `${tocBc.path}/${urlize(stateNames[address.state])}/${
    address.city ? urlize(address.city) : ''
  }`;
};

// This will trim "/next" from path
export const removeNextFromPath = path => {
  const arr = path.split('/next');
  return arr.length > 1 ? arr[1] : arr[0];
};

export const addNextToPath = path => {
  return '/next' + path;
};

export function parsePath(path) {
  let parsedPath = {};
  let search = '';

  if (path) {
    let hashIndex = path.indexOf('#');
    if (hashIndex >= 0) {
      parsedPath.hash = path.substr(hashIndex);
      path = path.substr(0, hashIndex);
    }

    let searchIndex = path.indexOf('?');
    if (searchIndex >= 0) {
      search = path.substr(searchIndex) || '';
      path = path.substr(0, searchIndex);
    }

    if (path) {
      parsedPath.pathname = path;
    }

    parsedPath.search = search;
  }

  return parsedPath;
}

export function transformCurrentLocationData(data) {
  const { city, state } = data;
  const formattedCity = urlize(city);
  const formattedState = urlize(stateNames[state]);
  const locationString = `${city}, ${state}`;

  return {
    ...data,
    name: locationString,
    description: locationString,
    displayName: locationString,
    searchParams: {
      city: formattedCity,
      state: formattedState
    }
  };
}
