import { abbreviatePrice } from '@common/helpers/abbreviatePrice';
import {
  CONVERSION_FIELDS,
  MONTHLY_BUDGET_MAP,
  nextStepsMap,
} from '@react/gwizzy/constants';

import { getResidentFirstAndLastNameByFullName } from '.';
import { isSelf } from './utils';

export const getBudgetRange = ({ pricesByCareType, values }) => {
  // if AA adult reutrn null
  const housingOptions = values?.[CONVERSION_FIELDS.housingOptions.name];
  if (housingOptions?.length) return null;

  const budgetRange = values?.[CONVERSION_FIELDS.budgetRange.name];

  if (budgetRange) return budgetRange;

  const nextStep = values?.[CONVERSION_FIELDS.nextStep.name];
  const adl = values?.[CONVERSION_FIELDS.adl.name];

  let finalPriceRange = MONTHLY_BUDGET_MAP.twoToFiveK;
  const priceInfo = pricesByCareType?.[adl];
  const price = priceInfo?.price;

  if (nextStep && nextStep !== nextStepsMap.none.value && price > 0) {
    if (price > 0) {
      finalPriceRange = `${abbreviatePrice(price)} - ${abbreviatePrice(
        price + 1000
      )}`;
    }
  }
  return finalPriceRange;
};

export const parseConversionUUIDActionData = (
  values,
  startingRate,
  pricesByCareType
) => {
  const data = Object.entries(values).reduce((acc, [fieldName, value]) => {
    if (fieldName === 'adl') {
      acc[fieldName] = [value];
    } else if (fieldName === CONVERSION_FIELDS.isWithinBudget.name) {
      if (value) {
        acc[CONVERSION_FIELDS.budgetRange.name] = startingRate
          ? getBudgetCategory(startingRate)
          : MONTHLY_BUDGET_MAP.twoToFiveK;
      }
    } else if (
      fieldName === 'homeSaleInterest' ||
      fieldName === 'backgroundCheck'
    ) {
      acc[fieldName] = value;
    } else if (fieldName === 'residentName') {
      const resident = getResidentFirstAndLastNameByFullName(value);
      if (resident) {
        acc['resident'] = resident;
      }
    } else {
      if (fieldName === 'location' && value?.searchParams) {
        acc['city'] = value.searchParams?.city;
        acc['state'] = value.searchParams?.state;
        if (value?.searchParams?.zip) {
          acc['zip'] = value.searchParams.zip;
        }
      }
      if (value) {
        acc[fieldName] = value;
      }
    }
    return acc;
  }, {});

  data[CONVERSION_FIELDS.budgetRange.name] = getBudgetRange({
    pricesByCareType,
    values: data,
  });

  if (data?.[CONVERSION_FIELDS.moreSteps.name]) {
    data[CONVERSION_FIELDS.nextStep.name] =
      data[CONVERSION_FIELDS.moreSteps.name];
    delete data[CONVERSION_FIELDS.moreSteps.name];
  }

  if (data?.tourTimeText && data?.tourDate) {
    data['tourDataInfo'] = [
      {
        tourTimeText: data?.tourTimeText?.value,
        tourDate: data?.tourDate,
        tourTimezone: 'America/Los_Angeles',
      },
    ];
    delete data['tourTimeText'];
    delete data['tourDate'];
  }

  // For self seachers set resident name by client name
  if (isSelf(values) && !data['resident'] && values?.firstName) {
    data['resident'] = {
      firstName: values?.firstName,
      lastName: values?.lastName,
    };
  }

  return data;
};

export function getBudgetCategory(startingRate) {
  if (startingRate < 2000) {
    return MONTHLY_BUDGET_MAP.under2K;
  } else if (startingRate <= 5000) {
    return MONTHLY_BUDGET_MAP.twoToFiveK;
  }

  return MONTHLY_BUDGET_MAP.over5K;
}
