import Recs from '@sly/frontend/react/recs';
import ToastProvider from '@sly/frontend/react/shared/components/Toast/ToastProvider';
import { IconContext } from '@sly/icons/Icon';
import dynamic from 'next/dynamic';
import React, { memo, useMemo } from 'react';
import { isBrowser } from 'sly/config';
import { ApiProvider, createApiClient } from 'sly/services/api';
import apiEndpoints from 'sly/services/api/endpoints';
import { AuthProvider } from 'sly/services/auth';
import { BreakpointProvider } from 'sly/services/breakpoint';
import ChatBoxProvider from 'sly/services/chatbox/ChatBoxContext';
import { UserCookiesContainer } from 'sly/services/cookies';
import SegmentContextProvider from 'sly/services/events/segmentContext';
import ErrorLogBoundary from 'sly/services/logger/components/ErrorLogBoundary';
import { PopupsProvider } from 'sly/services/popupsContext';
import RetentionPopupProvider from 'sly/services/retentionPopup/RetentionPopupProvider';

// import '../scripts/wdyr';
import '@sly/config/global.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

if (typeof window !== 'undefined' && 'ResizeObserver' in window === false) {
  console.log('polyfilling ResizeObserver');
  import('resize-observer').then(({ install }) => install());
}

if (isBrowser && window.HTMLDialogElement === undefined) {
  import('@react/shared/styles/dialog-polyfill.css');
}

import '@typeform/embed/build/css/widget.css';

const ChatWrapperWithDealy = dynamic(
  () =>
    import(
      '@sly/frontend/react/virtual-advisor/components/ChatWrapperNext'
    ).then((mod) => mod.ChatWrapperWithDealy),
  {
    ssr: false,
  }
);

function MyApp({ Component, pageProps, iconsContext = {} }) {
  const {
    initialApiState,
    enableNewChatBot = true,
    chatbotMode = 'default',
  } = pageProps;
  const apiContext = useMemo(
    () => ({
      apiClient: createApiClient(apiEndpoints, {
        initialState: initialApiState,
      }),
      skipApiCalls: false,
    }),
    []
  );

  return (
    <ErrorLogBoundary>
      <ApiProvider value={apiContext}>
        <AuthProvider>
          <UserCookiesContainer>
            <IconContext.Provider value={iconsContext}>
              <SegmentContextProvider>
                <PopupsProvider>
                  <RetentionPopupProvider>
                    <ChatBoxProvider>
                      <BreakpointProvider>
                        <ToastProvider variant='bottom_middle'>
                          <Component {...pageProps} />
                          <Recs />
                          {enableNewChatBot && (
                            <ChatWrapperWithDealy
                              chatbotMode={chatbotMode}
                              waitFor={15000}
                            />
                          )}
                        </ToastProvider>
                      </BreakpointProvider>
                    </ChatBoxProvider>
                  </RetentionPopupProvider>
                </PopupsProvider>
              </SegmentContextProvider>
            </IconContext.Provider>
          </UserCookiesContainer>
        </AuthProvider>
      </ApiProvider>
    </ErrorLogBoundary>
  );
}

export default memo(MyApp);
