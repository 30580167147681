export const titleize = inputString => {
  // TODO EXTREMELY POOR IMPL. PLEASE USE LIBRARY
  return (
    inputString &&
    inputString
      .split(/(\s|-)/g)
      .filter(e => e !== '-' && e !== ' ' && e !== '')
      .map(elem => {
        return `${elem[0].toUpperCase()}${elem.substring(1)}`;
      })
      .join(' ')
  );
};

export const commaAnd = list => {
  if (list.length > 1) {
    return `${list.slice(0, -1)} and ${list[list.length - 1]}`;
  } else if (list.length === 1) {
    return list[0];
  }
  return null;
};

export const titleCase = inputString => {
  return inputString
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const sentenceCase = inputString => {
  return inputString
    ? inputString[0].toUpperCase() + inputString.substr(1).toLowerCase()
    : '';
};

export const camelCaseToWords = inputString => {
  return inputString
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .toLowerCase()
    .split(' ')
    .map((word, index) => {
      if (index == 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    })
    .join(' ');
};

export const isVowel = char => {
  return ['a', 'e', 'i', 'o', 'u'].includes(char.toLowerCase());
};
