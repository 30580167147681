/** @jsxImportSource react */
import { standardizeSearchParam } from '@react/search/helpers';
import { Link } from '@react/system';

import { AdlLevel } from '../../types/index';

export const MismatchWarning = ({
  city,
  state,
  care,
  adl,
}: {
  city?: string;
  state?: string;
  care: string[];
  adl?: AdlLevel;
}) => {
  if (!adl || !care?.length || !city || !state) return <></>;

  if (
    care?.length <= 1 &&
    (care?.includes('Skilled Nursing Facility') ||
      care?.includes('Continuing Care Retirement Community(CCRC)'))
  )
    return <></>;

  const citySlug = standardizeSearchParam('city', city);
  const stateFullName = standardizeSearchParam('state', state);

  switch (adl) {
    case 'no-care':
      return (
        <>
          {!care.includes('Independent Living') && (
            <div className='font-b-s rounded bg-red-lighter-90 p-4 text-center'>
              {`This community may not be the best choice for your needs right now, as they only offer ${
                care?.length >= 2
                  ? `${care?.[0]?.toLowerCase()} and ${care?.[1]?.toLowerCase()}`
                  : care?.[0]?.toLowerCase()
              }. If you're looking for a community that offers independent living, `}
              <Link to={`/independent-living/${stateFullName}/${citySlug}`}>
                click here.
              </Link>
            </div>
          )}
        </>
      );
    case 'light-care':
      return (
        <>
          {!care?.includes('Independent Living') &&
            !care?.includes('Assisted Living') && (
              <div className='font-b-s rounded bg-red-lighter-90 p-4 text-center'>
                {`This community may not be the best choice if you need light care. If you're looking for a community that offers independent living, `}
                <Link to={`/independent-living/${stateFullName}/${citySlug}`}>
                  click here.
                </Link>

                {` Alternatively, If you're looking for a community that offers assisted living, `}
                <Link to={`/assisted-living/${stateFullName}/${citySlug}`}>
                  click here.
                </Link>
              </div>
            )}
        </>
      );
    case 'heavy-care':
      return (
        <>
          {!care?.includes('Assisted Living') && (
            <div className='font-b-s rounded bg-red-lighter-90 p-4 text-center'>
              {`This community may not be the best choice if you need assistance with acitivies of daily living. If you're looking for a community that offers assisted living, `}
              <Link to={`/assisted-living/${stateFullName}/${citySlug}`}>
                click here.
              </Link>
            </div>
          )}
        </>
      );
    case 'memory-care':
      return (
        <>
          {!care?.includes('Memory Care') && (
            <div className='font-b-s rounded bg-red-lighter-90 p-4 text-center'>
              {`This community may not be the best choice if you need memory care. If you're looking for a community that offers memory care, `}
              <Link to={`/memory-care/${stateFullName}/${citySlug}`}>
                click here.
              </Link>
            </div>
          )}
        </>
      );
    default:
      return <></>;
  }
};

export default MismatchWarning;
