/** @jsxImportSource react */
import proptyps from 'proptypes';
const { bool, string } = proptyps;
import clsxm from '@common/lib/clsxm';
import { Link } from '@react/system';
import React from 'react';

export const getTosLink = (isNewTab, isProvider = false) => (
  <Link
    className='font-medium'
    href={`/tos${isProvider ? '#participating-communities' : ''}`}
    target={isNewTab ? '_blank' : null}
    data-tp-id='gen-Link-3630a644-4a23-4473-89b1-3d303baa462e'
  >
    Terms of Use
  </Link>
);

export const getPrivacyLink = (isNewTab) => (
  <Link
    className='font-medium'
    href='/privacy'
    target={isNewTab ? '_blank' : null}
    data-tp-id='gen-Link-19899ef6-5eca-41b7-9e26-e64e0a148451'
  >
    Privacy Policy
  </Link>
);

export const TosAndPrivacy = ({
  openLinkInNewTab,
  className,
  isProvider = false,
}) => (
  <div
    className={clsxm('font-b-s text-xs text-slate-base', className)}
    data-tp-id='gen-div-487da0eb-9dab-4dd6-a71c-c0ac960bc123'
  >
    By continuing, you agree to Seniorly&apos;s{' '}
    {getTosLink(openLinkInNewTab, isProvider)} and{' '}
    {getPrivacyLink(openLinkInNewTab)}
  </div>
);

export const TosAndPrivacyCheckbox = ({ openLinkInNewTab, className }) => (
  <div
    className={clsxm('font-b-xs text-xs text-slate-base', className)}
    data-tp-id='gen-div-487da0eb-9dab-4dd6-a71c-c0ac960bc123'
  >
    <p>
      By checking the box &quot;I prefer receiving texts rather than getting
      phone calls&quot;, I agree to receive text messages related to my Seniorly
      inquiry and understand that message & data rates may apply.
    </p>
    <p>
      By continuing, you agree to Seniorly&apos;s {getTosLink(openLinkInNewTab)}{' '}
      and {getPrivacyLink(openLinkInNewTab)}.
    </p>
  </div>
);

TosAndPrivacy.propTypes = {
  openLinkInNewTab: bool,
  className: string,
};

export default TosAndPrivacy;
